import React from "react"
import styled from '@emotion/styled'
import { graphql } from "gatsby"
import contentParser from 'gatsby-wpgraphql-inline-images'
import Img from 'gatsby-image'

import Layout from "../components/layout"

import SEO from "../components/seo"

import BlogIntro from '../components/BlogIntro'
import { BlogContent } from '../blocks/content'


const FeatureImageContainer = styled.div`
  ${'' /* max-width: 1200px; */}
  margin: 0 auto;
  width: 100%;

  img {
    width: 100%;
    display: block;
  }
`

const MainContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  margin-top: 45px;
  margin-bottom: 90px;

  figcaption {
    border-top: 1px solid rgba(0,0,0,.1);
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: rgba(0,0,0,.5);
  }
`

const SideBar = styled.div`
  width: 350px;
`

const ContentContainer = styled.div`
  flex: 1;
  padding-right: 60px;

  h2 { 
    font-size: 32px;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
`
const Form = styled.form`
  width: 100%;
  font-size: 18px;

  input[type="text"], input[type="email"], input[type="tel"], textarea {
    border: 1px solid #D0D4D8;
    padding: 18px;
    margin-bottom: 20px;
    background: #F6F6F6;
    width: 100%;
  }

`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled.div`
  
  input[type="text"], input[type="email"], textarea {
    border: 1px solid #D0D4D8;
    padding: 18px;
    margin-bottom: 20px;
    background: #F6F6F6;
    width: 100%;
  }

  textarea {
    height: 200px;
  }
`

const Button = styled.button`
    background: #8DC63F;
    border: none;
    border-radius: 50px;
    padding: .5em 1.5em;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    font-size: 1.2em;
    max-width: 225px;
    

    box-shadow: 2px 2px 5px -1px rgba(0,0,0,0.15);
    transition: box-shadow 0.1s linear;

    &:hover {
        box-shadow: none;
    }

    @media (max-width: 767px) {
        font-size: 0.9em;
    }
`

const CardWrapper = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`
const CardImageWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
  }
`
const CardContentWrapper = styled.div`
  flex: 1;
  background-color: #48A49D;
  color: white;
  padding: 15px;

  h2 {
    margin: 0;
  }
`

export default function BlogPost({ data, pageContext }) {

  const { title, content, date, image } = pageContext;

  const wordPressUrl = "http://dev-northern-arizona-technology-alliance.pantheonsite.io/";
  const uploadsUrl = "http://dev-northern-arizona-technology-alliance.pantheonsite.io/wp-content/uploads/";

  return (
    <Layout>
      <SEO 
        title="About NATA"
        description="About Us Meta Description"
      />
      <BlogIntro title={title} date={date} />
      <FeatureImageContainer>
        <img src={image} />
      </FeatureImageContainer>

      <MainContent>
        <ContentContainer>
          <BlogContent
            children={contentParser({ content }, { wordPressUrl, uploadsUrl })}
          />
        </ContentContainer>
        <SideBar>
          <h2>Contact</h2>
          <Form name='blog-contact-form' method="POST" data-netlify="true" >
            <input type="hidden" name="form-name" value="blog-contact-form" />

            <FormContainer>
                <StyledInput>
                  <label htmlFor="full_name">Your Name</label>
                  <input type="text" name="full_name" id="full_name"/>
                </StyledInput>
                <StyledInput>
                  <label htmlFor="email">Your e-mail address</label>
                  <input type="email" name="email" id="email"/>
                </StyledInput>
                <StyledInput>
                  <label htmlFor="additional_info">Your Message</label>
                  <textarea rows="7" name="additional_info" id="additional_info" />
                </StyledInput>
                <Button type="submit">Submit</Button>
            </FormContainer>
          </Form>
          
          {/* <h2 style={{marginTop: '55px'}}>Newest Posts</h2>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CardWrapper>
              <CardImageWrapper>
                <Img fluid={data.tempImg.childImageSharp.fluid} />
              </CardImageWrapper>
              <CardContentWrapper>
                <h2>Lorem Ipsum Lorem Ipsum Lorem</h2>
              </CardContentWrapper>
            </CardWrapper>
            <CardWrapper>
            <CardImageWrapper>
              <Img fluid={data.tempImg.childImageSharp.fluid} />
            </CardImageWrapper>
            <CardContentWrapper>
              <h2>Lorem Ipsum Lorem Ipsum Lorem</h2>
            </CardContentWrapper>
          </CardWrapper>
          <CardWrapper>
          <CardImageWrapper>
            <Img fluid={data.tempImg.childImageSharp.fluid} />
          </CardImageWrapper>
          <CardContentWrapper>
            <h2>Lorem Ipsum Lorem Ipsum Lorem</h2>
          </CardContentWrapper>
        </CardWrapper>
          </div> */}
        </SideBar>
      </MainContent>

    </Layout>
  )
}


export const useQuery = graphql`
  query {
    tempImg: file(relativePath: { eq: "blog-post-temp-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 223) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`