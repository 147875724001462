import React from "react";
import styled from '@emotion/styled'

const DateArea = styled.span`
  font-size: 25px;
`

const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${'' /* max-width: 800px; */}
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top; 150px;
`

const StyledSection = styled.section`
  margin-top: 150px;
`

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-top: 0;
`

const BlogIntro = ({title, date}) => (

  <StyledSection className="container">
      <BlogContent>
        <DateArea pY={0}>{ date }</DateArea>
        <StyledH1 className="text--page-heading" dangerouslySetInnerHTML={{__html: title}} />
      </BlogContent>
  </StyledSection>
)

export default BlogIntro;