import React from "react"

export function BlogContent({children, html}) {
 
  return (
      <React.Fragment>
        { typeof html !== "undefined" ? (
          <div
            dangerouslySetInnerHTML={{
              __html: html,
          }}/>
        ) : (
          <div className="noHTML">
            {children}
          </div>
        ) }
      </React.Fragment>
  )
}